import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header id="header_main" className="header header-fixed">
              <div className="header-inner">
                <div className="header-inner-wrap">
                  <div id="site-logo">
                    <Link to="index.html" rel="home">
                      <img
                        className="d-block"
                        id="logo-header"
                        src="/images/logo/bhubaneswarproperty-logo.jpg"
                        alt=""
                        style={{
    width: '220px', // Set the desired width
    height: 'auto', // Maintain the aspect ratio
    }}
                      />
                    </Link>
                  </div>
                  <nav className="main-menu">
                    <ul className="navigation">
                      <li className="has-children">
                        <Link to="/home">Home</Link>
                        <ul>
                        
                      </ul>
                    </li>
                    <li className="has-children">
                      <Link to="javascript:void(0);">Property</Link>
                      <ul className="mega-menu">
                        <li>
                          <ul>
                            <li className="title">List view</li>
                            <li>
                              <Link to="/PropertyList">Property List 01</Link>
                            </li>
                            
                          </ul>
                        </li>
                        <li>
                              </li>
                        <li>
                          <ul>
                            <li className="title">Single view</li>
                            <li>
                              <Link to="/PropertySingle">
                                Property Single 01
                              </Link>
                            </li>
                                                     </ul>
                        </li>
                        <li>
                          </li>
                        </ul>
                      </li>
                      <li className="has-children">
                        <Link to="javascript:void(0);">Pages</Link>
                        <ul>
                          <li>
                            <Link to="about.html">About Us</Link>
                          </li>
                          <li>
                            <Link to="compare.html">Compare</Link>
                          </li>
                          <li>
                            <Link to="pricing.html">Pricing Packages</Link>
                          </li>
                          <li>
                            <Link to="faq.html">FAQ Page</Link>
                          </li>
                          <li>
                            <Link to="404.html">404 Page</Link>
                          </li>
                          <li>
                            <Link to="ui-elements.html">UI Elements</Link>
                          </li>
                          <li>
                            <Link to="dashboard.html">Dashboard</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-children">
                        <Link to="javascript:void(0);">Shop</Link>
                        <ul>
                          <li>
                            <Link to="shop-list.html">Shop List</Link>
                          </li>
                          <li>
                            <Link to="shop-single.html">Shop Single</Link>
                          </li>
                          <li>
                            <Link to="shop-cart.html">Shop Cart</Link>
                          </li>
                          <li>
                            <Link to="shop-checkout.html">Shop Checkout</Link>
                          </li>
                          <li>
                            <Link to="shop-order.html">Shop Order</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-children">
                        <Link to="javascript:void(0);">Blog</Link>
                        <ul>
                          <li>
                            <Link to="blog-list-v1.html">Blog List 01</Link>
                          </li>
                          <li>
                            <Link to="blog-list-v2.html">Blog List 02</Link>
                          </li>
                          <li>
                            <Link to="blog-list-v3.html">Blog List 03</Link>
                          </li>
                          <li>
                            <Link to="blog-single.html">Blog Single</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="contact.html">Contact</Link>
                      </li>
                    </ul>
                  </nav>
                  <div className="header-right">
                    <div className="header-call">
                      <div className="icon">
                        <i className="flaticon-phone" />
                      </div>
                      <div className="number">800-555-6789</div>
                    </div>
                    <div
                      data-bs-toggle="modal"
                      data-bs-target="#modallogin"
                      className="header-user"
                    >
                      <div className="icon">
                        <i className="flaticon-user" />
                      </div>
                    </div>
                    <div className="header-btn">
                      <Link
                        to="dashboard-add-properties.html"
                        className="tf-button-default"
                      >
                        Add Listing
                      </Link>
                    </div>
                  </div>
                  <a className="mobile-nav-toggler mobile-button" href="#menu" />
                </div>
              </div>
              <nav id="menu">
                <a className="close" aria-label="Close menu" href="#mm-22">
                  <i className="icon-close" />
                </a>
                <ul>
                  <li>
                    <span>Home</span>
                    <ul>
                      <li>
                        <Link to="index.html">Home Page 01</Link>
                      </li>
                      <li>
                        <Link to="home-02.html">Home Page 02</Link>
                      </li>
                      <li>
                        <Link to="home-03.html">Home Page 03</Link>
                      </li>
                      <li>
                        <Link to="home-04.html">Home Page 04</Link>
                      </li>
                      <li>
                        <Link to="home-05.html">Home Page 05</Link>
                      </li>
                      <li>
                        <Link to="home-06.html">Home Page 06</Link>
                      </li>
                      <li>
                        <Link to="home-07.html">Home Page 07</Link>
                      </li>
                      <li>
                        <Link to="home-08.html">Home Page 08</Link>
                      </li>
                      <li>
                        <Link to="home-09.html">Home Page 09</Link>
                      </li>
                      <li>
                        <Link to="home-10.html">Home Page 10</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="current">
                    <span>Property</span>
                    <ul>
                      <li className="current">
                        <span>List view</span>
                        <ul>
                          <li className="current">
                            <Link to="property-list-v1.html">Property List 01</Link>
                          </li>
                          <li>
                            <Link to="property-list-v2.html">Property List 02</Link>
                          </li>
                          <li>
                            <Link to="property-list-v3.html">Property List 03</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Grid view</span>
                        <ul>
                          <li>
                            <Link to="property-grid-v1.html">Property Grid 01</Link>
                          </li>
                          <li>
                            <Link to="property-grid-v2.html">Property Grid 02</Link>
                          </li>
                          <li>
                            <Link to="property-grid-v3.html">Property Grid 03</Link>
                          </li>
                          <li>
                            <Link to="property-grid-v4.html">Property Grid 04</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Single view</span>
                        <ul>
                          <li>
                            <Link to="property-single-v1.html">Property Single 01</Link>
                          </li>
                          <li>
                            <Link to="property-single-v2.html">Property Single 02</Link>
                          </li>
                          <li>
                            <Link to="property-single-v3.html">Property Single 03</Link>
                          </li>
                          <li>
                            <Link to="property-single-v4.html">Property Single 04</Link>
                          </li>
                          <li>
                            <Link to="property-single-v5.html">Property Single 05</Link>
                          </li>
                          <li>
                            <Link to="property-single-v6.html">Property Single 06</Link>
                          </li>
                          <li>
                            <Link to="property-single-v7.html">Property Single 07</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                       </li>
                    </ul>
                  </li>
                  <li>
                   </li>
                  <li>
                    <span>Pages</span>
                    <ul>
                      <li>
                        <Link to="about.html">About Us</Link>
                      </li>
                      <li>
                        <Link to="compare.html">Compare</Link>
                      </li>
                      <li>
                        <Link to="pricing.html">Pricing Packages</Link>
                      </li>
                      <li>
                        <Link to="faq.html">FAQ Page</Link>
                      </li>
                      <li>
                        <Link to="404.html">404 Page</Link>
                      </li>
                      <li>
                        <Link to="ui-elements.html">UI Elements</Link>
                      </li>
                      <li>
                        <Link to="dashboard.html">Dashboard</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>Shop</span>
                    <ul>
                      <li>
                        <Link to="shop-list.html">Shop List</Link>
                      </li>
                      <li>
                        <Link to="shop-single.html">Shop Single</Link>
                      </li>
                      <li>
                        <Link to="shop-cart.html">Shop Cart</Link>
                      </li>
                      <li>
                        <Link to="shop-checkout.html">Shop Checkout</Link>
                      </li>
                      <li>
                        <Link to="shop-order.html">Shop Order</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>Blog</span>
                    <ul>
                      <li>
                        <Link to="blog-list-v1.html">Blog List 01</Link>
                      </li>
                      <li>
                        <Link to="blog-list-v2.html">Blog List 02</Link>
                      </li>
                      <li>
                        <Link to="blog-list-v3.html">Blog List 03</Link>
                      </li>
                      <li>
                        <Link to="blog-single.html">Blog Single</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="contact.html">Contact</Link>
                  </li>
                </ul>
              </nav>
            </header>
                 );
}

export default Header;
